.masonry-grid {
    display: flex;
    width: 100%;
}

.masonry-grid_column {
    padding-left: 16px;
    background-clip: padding-box;
}

.masonry-item {
    margin-bottom: 16px;
}
