@keyframes ring {
    0% {
        transform: translateY(-50%) rotate(0deg);
    }
    10% {
        transform: translateY(-50%) rotate(15deg);
    }
    20% {
        transform: translateY(-50%) rotate(-10deg);
    }
    30% {
        transform: translateY(-50%) rotate(15deg);
    }
    40% {
        transform: translateY(-50%) rotate(-10deg);
    }
    50% {
        transform: translateY(-50%) rotate(10deg);
    }
    60% {
        transform: translateY(-50%) rotate(-5deg);
    }
    70% {
        transform: translateY(-50%) rotate(5deg);
    }
    80% {
        transform: translateY(-50%) rotate(0deg);
    }
    100% {
        transform: translateY(-50%) rotate(0deg);
    }
}

.table-transition {
    transition: height 0.3s ease-in-out;
}
